import "./src/styles/global.css";

// TODO: would be nice to avoid loading all this stuff until /api_documentation is hit
// CodeMirror throws "Failed to Server Render (SSR)" otherwise
require("codemirror/lib/codemirror.css");
require("codemirror/theme/lucario.css");
require("codemirror/mode/javascript/javascript");
require("codemirror/mode/yaml/yaml");

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
